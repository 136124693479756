import logo from "./assets/black-logo.svg";
import questions from "./assets/questions.svg";

import footerlogo from "./assets/white-logo.svg";
import Heroimage from "./assets/hero-img.svg";
import Partnerlogo from "./assets/Partnerlogo.svg";
import convrtxlogo from "./assets/convrtx-logo.png";
import featuresicon1 from "./assets/icon-1.svg";
import featuresicon2 from "./assets/icon-2.svg";
import featuresicon3 from "./assets/icon-3.svg";
import featuresicon4 from "./assets/icon-4.svg";
import featuresheart from "./assets/heart.svg";
import whyicon1 from "./assets/why-icon-1.svg";
import whyicon2 from "./assets/why-icon-2.svg";
import whyicon3 from "./assets/why-icon-3.svg";
import whyicon4 from "./assets/why-icon-4.svg";
import facebook from "./assets/facebook.svg";
import linkedin from "./assets/inkedin.svg";
import twitterIcon from "./assets/bg-features.svg";
import instagram from "./assets/instagram.svg";
import "./App.css";
import { Fragment, useState, useEffect, useCallback } from "react";
import { Popover, Transition, Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";

import AOS from "aos";
import Tilt from "react-tilt";
import "aos/dist/aos.css";

const navigation = {
	main: [
		{ name: "Home", href: "#" },
		{ name: "Features", href: "#features" },
		{ name: "Why Peace Maker", href: "#why-peacemaker" },
		{ name: "Contact Us", href: "mailto:support@peacemakerapp.com" },
	],
	social: [
		{
			name: "instagram",
			href: "https://www.instagram.com/peacemakerapp/",
			icon: instagram,
		},
		{
			name: "linkedin",
			href: "https://www.linkedin.com/company/81679962/admin/",
			icon: linkedin,
		},
		{
			name: "facebook",
			href: "https://www.facebook.com/Peace-Maker-App-105984788647124",
			icon: facebook,
		},
		{
			name: "twitter",
			href: "https://twitter.com/PeaceMakerApp",
			icon: twitterIcon,
		},
	],
};

const faqs = [
	{
		question: "Does Peace Maker share personal information?",
		answer: "No, Peace Maker is passionate that your information is personal and will not be shared with any outside organizations for marketing or anything else. Peace Maker uses HIPAA compliant servers to ensure your information stays personal.",
	},
	{
		question:
			"What security measures are in place to protect personal information?",
		answer: "Peace Maker uses secure encryption and HIPAA compliant servers to ensure our users safety and privacy.",
	},
	{
		question: "Is my personal information ever shared?",
		answer: "You are in control of your personal information. If you choose to share your data with a health care provider or accountability partner through the app, your data is secure. Peace Maker will never share your personal data with any outside organization.",
	},
	{
		question: "How does Peace Maker use AI to help?",
		answer: (
			<div>
				<p>
					a. Peace Maker uses AI to pick up on your personal triggers.
					Triggers are things that change your mood suddenly or
					drastically.
				</p>
				<p>
					b. Peace Maker’s AI learns you to help you manage your
					personal challenges and monitors your information to assist
					health care providers with treatments.
				</p>
				<p>
					c. Peace Maker’s AI tailors your daily challenges to
					methodically help you over time.
				</p>
			</div>
		),
	},
	{
		question: "How do daily challenges help me?",
		answer: "Daily challenges are short achievable challenges designed to reprogram your brain for mental clarity, deeper relationships, and attainable success. Where some mental health apps only focus on mindfulness and meditation, Peace Maker challenges you to develop yourself wholistically. Daily challenges help.",
	},
	{
		question: "Does Peace Maker work with my current fitness watch?",
		answer: "No. Peace Maker will launch our own fitness watch DEC 22.",
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function App() {
	AOS.init();
	//Scroll Direction
	const [y, setY] = useState(document.scrollingElement.scrollHeight);
	const [scrollDirection, setScrollDirection] = useState(
		"fixed top-0 w-full z-50 transition-all duration-200 bg-transparent"
	);

	const handleNavigation = useCallback(
		(e) => {
			if (y > window.scrollY) {
				setScrollDirection(
					"fixed top-0 w-full z-50 transition-all duration-200"
				);
			} else if (10 < window.scrollY) {
				setScrollDirection(
					"fixed -top-full w-full z-50 transition-all duration-200"
				);
			}
			setY(window.scrollY);
		},
		[y]
	);

	useEffect(() => {
		window.addEventListener("scroll", handleNavigation);

		return () => {
			window.removeEventListener("scroll", handleNavigation);
		};
	}, [handleNavigation]);

	//Scroll to top
	const useScrollHandler = () => {
		const [scroll, setScroll] = useState(1);

		useEffect(() => {
			const onScroll = () => {
				const scrollCheck = window.scrollY <= 30;
				setScroll(scrollCheck);
			};

			document.addEventListener("scroll", onScroll);
			return () => {
				document.removeEventListener("scroll", onScroll);
			};
		}, [scroll, setScroll]);

		return scroll;
	};

	const scroll = useScrollHandler();

	return (
		<div className="App">
			{/**NAVIGATION */}
			<div className={scrollDirection}>
				<Disclosure
					as="header"
					className={
						scroll
							? "bg-transparent transition duration-100"
							: "transition-all duration-200 bg-white shadow-lg"
					}
				>
					<div className="max-w-7xl mx-auto px-4 sm:px-6">
						<div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
							<div className="flex justify-start lg:w-0 lg:flex-1">
								<a href="#">
									<span className="sr-only">PeaceMaker</span>
									<img
										className={
											scroll
												? "pl-2 w-28 lg:pt-4 sm:pt-0 sm:w-30 scale-100 transition duration-200 absolute top-2 sm:top-1.5 left-0 sm:left-auto"
												: "w-28 sm:w-30 scale-75 transition duration-200 absolute top-2 left-0 sm:left-auto sm:top-2"
										}
										src={logo}
										alt="image"
									/>
								</a>
							</div>
							<div className="-mr-2 -my-2 md:hidden">
								<Disclosure.Button className="relative bg-green-2 rounded-md p-4 inline-flex items-center justify-center text-white hover:bg-green-3 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-3">
									<span className="sr-only">Open menu</span>
									<MenuIcon
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</Disclosure.Button>
							</div>

							<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
								<a
									href="#"
									className="ml-8 whitespace-nowrap text-base font-medium text-black hover:text-black"
								>
									Home
								</a>
								<a
									href="#features"
									className="ml-8 whitespace-nowrap text-base font-medium text-black hover:text-black"
								>
									Features
								</a>
								<a
									href="#why-peacemaker"
									className="ml-8 whitespace-nowrap text-base font-medium text-black hover:text-black"
								>
									Why Peace Maker
								</a>
								<a
									href="https://form.jotform.com/220127583392152"
									className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-green-1 hover:bg-green-3"
								>
									Pre Sign-up
								</a>
							</div>
						</div>
					</div>

					<Transition
						as={Fragment}
						enter="duration-200 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Disclosure.Panel
							focus
							className="absolute top-0 inset-x-0 z-10 transition transform origin-top-right md:hidden"
						>
							{({ close }) => (
								<div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
									<div className="pt-1 pb-1 px-3">
										<div className="flex items-center justify-between">
											<div>
												<img
													className="h-20 w-auto"
													src={logo}
													alt="Workflow"
												/>
											</div>
											<div className="-mr-2">
												<Disclosure.Button className="bg-white rounded-md p-4 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
													<span className="sr-only">
														Close menu
													</span>
													<XIcon
														className="h-6 w-6"
														aria-hidden="true"
													/>
												</Disclosure.Button>
											</div>
										</div>
									</div>
									<div className="py-6 space-y-6 pb-10">
										<div className="grid grid-cols-1 text-left gap-y-4 px-5">
											<a
												href="#"
												className="text-base font-medium text-gray-900 hover:text-gray-700"
												onClick={async () => {
													close();
												}}
											>
												Home
											</a>
											<a
												href="#features"
												className="text-base font-medium text-gray-900 hover:text-gray-700"
											>
												Features
											</a>
											<a
												href="#why-peacemaker"
												className="text-base font-medium text-gray-900 hover:text-gray-700"
												onClick={async () => {
													close();
												}}
											>
												Why Peace Maker
											</a>
										</div>
										<div>
											<a
												href="https://form.jotform.com/220127583392152"
												className="w-full flex items-center justify-left px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-green-1 hover:bg-green-4"
												onClick={async () => {
													close();
												}}
											>
												Pre Sign-up
											</a>
										</div>
									</div>
								</div>
							)}
						</Disclosure.Panel>
					</Transition>
				</Disclosure>
			</div>

			{/** HERO BANNER */}
			<section id="home">
				<div className="relative">
					<main className="lg:relative">
						<div className="z-20 -mb-20 w-full pt-32 top-0 block sm:-mb-52 md:-mb-60 md:-mt-10 md:pt-36 lg:hidden">
							<img
								className="xl:w-4/5 max-w-750 mx-auto my-auto w-7/12 top-52 left-4 xl:-left-8 xl:top-28 xl:left-20 z-50"
								src={Heroimage}
								alt="Heropic"
							></img>
						</div>
						<div className="relative mx-auto max-w-7xl w-full pb-40 md:pb-96 lg:pb-52 text-center lg:pt-52 lg:text-left">
							<div
								className="relative z-30 top-24 sm:top-60 lg:top-0 px-4 sm:px-8 xl:pr-16 lg:w-3/5 xl:w-1/2 aos"
								data-aos="fade-right"
								data-aos-duration="600"
							>
								<h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl md:leading-tight lg:text-5xl lg:leading-tight xl:text-5xl xl:leading-tight">
									Treat Your Mind Well Every Day!
								</h1>
								<p className="font-base mt-3 max-w-md mx-auto text-lg text-gray-900 sm:text-2xl md:mt-5 md:max-w-2xl">
									Let The Peace Maker in your Phone Help
								</p>
								<p className="font-light mt-3 max-w-md mx-auto text-sm text-gray-500 sm:text-lg lg:text-[0.9rem] md:mt-5 md:max-w-3xl">
									We at Peace Maker develop digital
									applications and software that helps to
									encourage people to invest direct effort
									into improving their mental health. We do
									this by creating virtual, AI-enabled,
									process through which users and behavioral
									health professionals can interact in a
									continual, holistic process of observation,
									reflection, and coaching to improve mental
									health and quality of life.
								</p>
								<div className="mt-10 sm:flex sm:justify-center lg:justify-start">
									<div className="rounded-full shadow">
										<a
											href="https://form.jotform.com/220127583392152"
											className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-full text-white bg-green-2 hover:bg-green-3 md:py-4 md:text-lg md:px-10"
										>
											Pre Sign-up
										</a>
									</div>
									<div className="mt-3 rounded-full shadow sm:mt-0 sm:ml-3">
										<a
											href="#why-peacemaker"
											className="w-full flex items-center justify-center px-8 py-3 border border-green-2 text-base font-medium rounded-full text-black bg-transparent hover:bg-green-2 hover:text-white md:py-4 md:text-lg md:px-10"
										>
											Why Peace Maker
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="z-20 relative w-full h-64 sm:h-72 hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5 lg:h-full lg:top-0 lg:overflow-hidden xl:w-1/2">
							<img
								className="relative xl:w-4/5 lg:absolute max-w-750 top-52 left-4 xl:-left-8 xl:top-28 xl:left-20 z-50"
								src={Heroimage}
								alt="Heropic"
							></img>
						</div>
						<div className="z-10 relative w-full h-64 -top-10 sm:h-72 hidden xl:block lg:absolute right-[99rem] xl:right-[106rem] top-28 md:w-1/2">
							<div className="absolute -top-60 z-0 rectangle bg-white shadow-xl opacity-50  "></div>
						</div>
					</main>
				</div>
			</section>

			{/** PARTNER SECTION */}

			<section className="z-10 relative pt-10 sm:pt-[10rem] md:pt-0 pb-[5rem] 2xl:top-[10rem]">
				<div className="max-w-7xl pt-0 lg:pt-0 vp-1466:pb-72 mx-auto">
					<div className="justify-items-center md:space-y-0 grid grid-cols-1 md:grid-cols-2 gap-y-16">
						<div className="mx-auto max-w-xs vp-540:max-w-md md:pr-0 lg:max-w-none">
							<div>
								<img
									className="mx-auto md:mx-0"
									src={Partnerlogo}
									alt="Partnerlogo"
									data-aos="fade-right"
									data-aos-duration="600"
								></img>
							</div>
							<div className="pt-6">
								<p
									className="font-semibold text-sm text-center px-5 sm:px-0 md:text-left lg:text-xl xl:text-3xl aos"
									data-aos="fade-right"
									data-aos-duration="600"
								>
									We have partnered with awesome people
								</p>
							</div>
						</div>
						<div data-aos="fade-left" data-aos-duration="600">
							<img
								className=""
								src={convrtxlogo}
								alt="convrtxlogo"
							></img>
						</div>
					</div>
				</div>
			</section>

			{/** FEATURES SECION */}
			<section
				id="features"
				className="relative mb-10 pb-10 pt-20 md:pb-20 xl:mb-40"
			>
				<div className="shapes-container">
					<div className="shape background-shape-main"></div>
				</div>
				<div className="relative mx-auto max-w-7xl w-full text-center sm:px-5 md:px-8 lg:text-left grid lg:grid-cols-2 xl:grid-cols-2 lg:pt-[250px]">
					<div className="block lg:hidden relative z-20 pb-10 md:pb-10 text-center">
						<h2 className="text-4xl sm:text-6xl font-bold heading">
							Discover Features
						</h2>
					</div>

					<div className="relative z-20 md:w-10/12 mx-5 sm:mx-auto lg:w-11/12 xl:mx-0 grid sm:grid-cols-2 gap-5 md:gap-8">
						<Tilt
							options={{ max: 25, transition: true }}
							className=" rounded-3xl px-5 py-5 xl:py-10 bg-green-3 Tilt-inner"
							data-aos="fade-up"
							data-aos-duration="600"
						>
							<img
								src={featuresicon1}
								className="mb-2 mx-auto lg:mx-0 lg:w-20 xl:w-auto"
							/>
							<h3 className="md:text-xl xl:text-2xl font-bold text-white lg:mb-2 xl:mb-5">
								"Action" Feature
							</h3>
							<p className="lg:text-sm xl:text-xl font-light text-white">
								Peace Maker uses a three step AI intervention
								process for support when you need it most.
							</p>
						</Tilt>

						<Tilt
							options={{ max: 25, transition: true }}
							className="rounded-3xl px-5 py-5 xl:py-10 bg-green-3 Tilt-inner"
							data-aos="fade-up"
							data-aos-duration="750"
						>
							<img
								src={featuresicon2}
								className="mb-2 mx-auto lg:mx-0 lg:w-20 xl:w-auto"
							/>
							<h3 className="md:text-xl xl:text-2xl font-bold text-white lg:mb-2 xl:mb-5">
								Dementia support
							</h3>
							<p className="lg:text-sm xl:text-xl font-light text-white">
								Peace Maker utilizes geofencing features and
								personal memory assistance features to assist
								caregivers.
							</p>
						</Tilt>

						<Tilt
							options={{ max: 25, transition: true }}
							className="rounded-3xl px-5 py-5 xl:py-10 bg-green-3"
							data-aos="fade-up"
							data-aos-duration="850"
						>
							<img
								src={featuresicon3}
								className="mb-2 mx-auto lg:mx-0 lg:w-20 xl:w-auto"
							/>
							<h3 className="md:text-xl xl:text-2xl font-bold text-white lg:mb-2 xl:mb-5">
								Rewards
							</h3>
							<p className="lg:text-sm xl:text-xl font-light text-white">
								Goal completions are reinforced with tangible
								rewards.
								<br />
								<br />
							</p>
						</Tilt>

						<Tilt
							options={{ max: 25, transition: true }}
							className="rounded-3xl px-5 py-5 xl:py-10 bg-green-3"
							data-aos="fade-up"
							data-aos-duration="950"
						>
							<img
								src={featuresicon4}
								className="mb-2 mx-auto lg:mx-0 lg:w-20 xl:w-auto"
							/>
							<h3 className="md:text-xl xl:text-2xl font-bold text-white lg:mb-2 xl:mb-5">
								Provider Support
							</h3>
							<p className="lg:text-sm xl:text-xl font-light text-white">
								Peace Maker feeds information to providers that
								supports patient treatment monitoring and
								refinement.
							</p>
						</Tilt>
					</div>

					<div className="relative z-20 p-10 lg:p-20 text-center lg:left-0 xl:left-0 2xl:left-20 lg:w-[37rem] xl:w-[46rem] 2xl:w-[46rem]">
						<div className="hidden md:block">
							<img
								className="pb-12 mx-auto max-w-[70%] lg:max-w-full sm:mx-auto xl:max-w-full xl:mx-auto 2xl:max-w-full 2xl:mx-auto"
								src={featuresheart}
							></img>
						</div>
						<h2 className="hidden lg:block text-black lg:text-2xl xl:text-3xl 2xl:text-2x5rem font-bold mb-8">
							DISCOVER FEATURES
						</h2>
						<a
							href="https://form.jotform.com/220127583392152"
							className="mx-auto py-4 px-10 text-2xl whitespace-nowrap inline-flex items-center justify-center lg:text-xl lg:px-8 lg:py-4 xl:px-10 xl:py-4 border border-transparent rounded-full shadow-sm xl:text-2xl font-medium text-white bg-green-3 hover:bg-green-2"
						>
							Pre Sign-up
						</a>
					</div>
				</div>
			</section>

			{/** ABOUT SECION */}

			<section id="why-peacemaker" className="py-10 md:pt-60 md:pb-20">
				<div className="relative z-20 px-5 text-center mx-auto lg:max-w-4xl">
					<h2
						className="text-4xl md:text-6xl font-bold mb-8 heading"
						data-aos="fade-up"
						data-aos-duration="600"
					>
						Why Choose Peace Maker
					</h2>

					<p
						className="mb-5 text-lg font-light text-gray-500"
						data-aos="fade-right"
						data-aos-duration="600"
					>
						Peace Maker is the only app that implements a real time
						intervention capability when you need it most. All
						monitoring and tracking systems exist to intervene the
						right way at the right time.
					</p>

					<p
						className="mb-5 text-lg font-light text-gray-500"
						data-aos="fade-left"
						data-aos-duration="600"
					>
						Peace Maker App uses a virtuous approach to healing and
						improvement. You are not a victim and you were created
						for a purpose. Peace Maker pushes you to improve in
						small steps every day not just to help you, but to
						improve your personal community.
					</p>

					<p
						className="mb-5 text-lg font-light text-gray-500"
						data-aos="fade-left"
						data-aos-duration="600"
					>
						We believe in a holistic health approach. Your mind,
						body, and spirit are connected and therefore affect one
						another. Peace Maker App applies this approach to your
						experience to ensure nothing is missed.
					</p>

					<p
						className="mb-5 text-lg font-light text-gray-500"
						data-aos="fade-right"
						data-aos-duration="600"
					>
						Peace Maker app builds your accountability group,
						enabling those you trust most to hold you accountable
						for achieving your goals. You can share your progress
						with your accountability group in app. Peace Maker
						alerts your accountability group during an intervention
						for maximum support.
					</p>

					<p
						className="mb-5 text-lg font-light text-gray-500"
						data-aos="fade-left"
						data-aos-duration="600"
					>
						We pride ourselves on the highest level of security. The
						Peace Maker team uses HIPAA compliant servers, top
						quality security protocol, and we pledge never to turn
						over any of your data to any agency foreign or domestic.
					</p>
				</div>
			</section>

			{/** BEFENITS SECION */}

			<section>
				<div className="relative overflow-hidden">
					<main className="lg:relative pt-16 pb-20 lg:py-20">
						<h1
							data-aos="fade-left"
							data-aos-duration="600"
							className="block px-4 sm:px-8 text-center lg:text-left mx-auto md:max-w-7xl xl:hidden text-3xl sm:text-4xl md:text-6xl font-bold mb-8"
						>
							<span className="block xl:inline">
								The 3 Step Approach
							</span>
						</h1>
						<p className="block px-4 sm:px-8 text-center lg:text-left xl:hidden mt-3 mx-auto text-lg font-light text-gray-500 max-w-7xl">
							Peace Maker logs your daily life patterns and habits
							to create prompts that invoke stressors in your lie
							to help you overcome each trial.
						</p>

						<div className="mx-auto max-w-7xl w-full text-center lg:text-left">
							<div className="px-4 pr-4 lg:w-3/5 sm:px-8 sm:pr-4 xl:pr-16">
								<h1 className="hidden xl:block text-4xl md:text-6xl font-bold mb-8">
									<span className="block xl:inline">
										The 3 Step Approach
									</span>
								</h1>
								<p className="hidden xl:block mt-3 max-w-md mx-auto text-lg font-light text-gray-500 md:max-w-3xl">
									Peace Maker logs your daily life patterns
									and habits to create prompts that invoke
									stressors in your lie to help you overcome
									each trial.
								</p>

								<dl
									className="mt-10 mb-10 lg:space-y-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-1"
									data-aos="fade-right"
									data-aos-duration="600"
								>
									<div className="relative px-2 mb-14 md:mb-0">
										<dt>
											<div className="mx-auto mb-5 lg:mb-0 lg:absolute lg:flex items-center justify-center h-20 w-20 rounded-md">
												<img
													src={whyicon1}
													className="h-20 w-20"
													aria-hidden="true"
												/>
											</div>
											<p className="md:text-xl xl:text-2xl lg:ml-24 text-lg leading-6 font-medium text-gray-900">
												1. Warning
											</p>
										</dt>
										<dd className="mt-2 lg:ml-24 text-base text-gray-500 font-light">
											The app AI customizes your life
											patterns to activate a multimode
											alert system during or before an
											emotional escalation in an effort to
											improve your relationships and
											manage emotional intensity.
										</dd>
									</div>
									<div className="relative px-2 mb-14 md:mb-0">
										<dt>
											<div className="mx-auto mb-5 lg:mb-0 lg:absolute lg:flex items-center justify-center h-20 w-20 rounded-md">
												<img
													src={whyicon2}
													className="h-20 w-20"
													aria-hidden="true"
												/>
											</div>
											<p className="md:text-xl xl:text-2xl lg:ml-24 text-lg leading-6 font-medium text-gray-900">
												2. Prompt
											</p>
										</dt>
										<dd className="mt-2 lg:ml-24 text-base text-gray-500 font-light">
											Following the alert pattern the app
											prompts immediate action from you
											and your accountability group.
										</dd>
									</div>
									<div className="relative px-2 mb-14 md:mb-0">
										<dt>
											<div className="mx-auto mb-5 lg:mb-0 lg:absolute lg:flex items-center justify-center h-20 w-20 rounded-md">
												<img
													src={whyicon3}
													className=" h-20 w-20"
													aria-hidden="true"
												/>
											</div>
											<p className="md:text-xl xl:text-2xl lg:ml-24 text-lg leading-6 font-medium text-gray-900">
												3. Sustainable Activities
											</p>
										</dt>
										<dd className="mt-2 lg:ml-24 text-base text-gray-500 font-light">
											The app will encourages you to
											implement virtuous activities daily
											to improve your holistic helath and
											well-being.
										</dd>
									</div>
								</dl>
								<a
									href="https://form.jotform.com/220127583392152"
									className="mx-auto lg:ml-24 py-4 px-10 text-2xl whitespace-nowrap lg:text-xl lg:px-8 lg:py-4 xl:px-10 xl:py-4 border border-transparent rounded-full shadow-sm xl:text-2xl font-medium text-white bg-green-3 hover:bg-green-2"
								>
									Pre Sign-up
								</a>
							</div>
						</div>
						<div
							className="hidden relative w-full mx-10 h-64 sm:h-72 lg:block md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/5 lg:h-full xl:absolute xl:inset-y-0 xl:right-0 xl:w-2/5 xl:h-full"
							data-aos="fade-left"
							data-aos-duration="600"
						>
							<img
								className="absolute top-80 2xl:top-[7rem] bottom-20 w-full"
								src={whyicon4}
								alt="image"
							/>
						</div>
					</main>
				</div>
			</section>

			{/** FAQ */}

			<section className="overflow-hidden">
				<div className="trianglebottomright hidden md:block"></div>
				<div className="bg-green-3">
					<div className="max-w-7xl mx-auto py-12 px-4 sm:py-20 sm:px-6 lg:px-8">
						<div
							className="max-w-3xl mx-auto"
							data-aos="fade-up"
							data-aos-duration="600"
						>
							<h2 className="text-center mb-4 font-extrabold text-gray-900 text-4xl md:text-5xl">
								Frequently asked questions
							</h2>
							<p className="mb-5 text-lg font-light text-center text-gray-900">
								Want to know more about our services?
							</p>
							<dl className="mt-6">
								{faqs.map((faq) => (
									<Disclosure
										as="div"
										key={faq.question}
										className="mt-5 bg-white rounded-md"
									>
										{({ open }) => (
											<>
												<dt className="text-lg py-3 px-5">
													<Disclosure.Button className="text-left w-full flex justify-between items-center text-gray-400">
														<span className="text-xs sm:text-xl font-medium text-gray-900">
															{faq.question}
														</span>
														<span className="ml-6 h-7 flex items-center">
															<ChevronDownIcon
																className={classNames(
																	open
																		? "-rotate-180"
																		: "rotate-0",
																	"h-6 w-6 transform"
																)}
																aria-hidden="true"
															/>
														</span>
													</Disclosure.Button>
												</dt>
												<Transition
													enter="transition duration-300 ease-out"
													enterFrom="transform height-95 opacity-0"
													enterTo="transform duration-300 scale-100 opacity-100"
													leave="transition duration-75 ease-out"
													leaveFrom="transform height-100 opacity-100"
													leaveTo="transform height-95 opacity-0"
												>
													<Disclosure.Panel as="dd">
														<p className="py-6 px-5 text-xs sm:text-base font-light rounded-b-md bg-green-4 text-white">
															{faq.answer}
														</p>
													</Disclosure.Panel>
												</Transition>
											</>
										)}
									</Disclosure>
								))}
							</dl>
						</div>
					</div>
				</div>
			</section>

			{/** Find your People */}
			<section>
				<div
					className="relative text-center pt-16 pb-20 md:mb-20 lg:py-20"
					data-aos="fade-up"
					data-aos-duration="600"
				>
					<h1 className="block px-4 sm:px-8 text-center mx-auto md:max-w-7xl text-3xl sm:text-4xl md:text-5xl font-bold mb-8">
						<span className="block xl:inline heading">
							Prioritize Your Mental Health!
						</span>
					</h1>
					<p className="mb-10 text-lg font-light text-center text-gray-500">
						Sign up to be the first ones to download the app as soon
						as it hits the app store.
					</p>
					<a
						href="https://form.jotform.com/220127583392152"
						className="mx-auto py-4 px-10 text-2xl whitespace-nowrap lg:text-xl lg:px-8 lg:py-4 xl:px-10 xl:py-4 border border-transparent rounded-full shadow-sm xl:text-2xl font-medium text-white bg-green-3 hover:bg-green-2"
					>
						Pre Sign-up
					</a>
				</div>
			</section>

			{/** Contact */}
			<section className="relative z-20">
				<div data-aos="fade-in" data-aos-duration="700">
					<div className="max-w-full md:max-w-2xl text-center md:text-left mx-auto md:rounded-3xl px-10 py-20 xl:py-10 relative md:mb-0 bg-slate-200">
						<dt>
							<div className="mx-auto mb-5 bg-green-3 inline-block lg:mb-0 md:absolute md:flex items-center justify-center h-22 w-22 rounded-full">
								<img
									src={questions}
									className="p-2 h-20 w-20"
									aria-hidden="true"
								/>
							</div>
							<h2 className="md:text-3xl xl:text-2xl md:ml-24 text-lg leading-6 font-medium text-gray-900">
								Help & Support
							</h2>
						</dt>
						<dd className="mt-2 text-xs md:text-base md:ml-24 text-green-1 font-light">
							User questions and feedback.
						</dd>
						<p className="mt-2 md:ml-20 text-base md:text-lg text-gray-500 font-light">
							Feel free to email us for any questions or feedback
							you would like to provide at{" "}
							<a
								href="mailto:support@peacemakerapp.com"
								className="font-medium text-green-3"
							>
								support@peacemakerapp.com
							</a>
						</p>
					</div>
				</div>
			</section>

			{/** Footer */}
			<section className="relative">
				<div className="hidden md:block trianglebottomrightlime position absolute -translate-y-full"></div>
				<footer className="bg-green-4 relative">
					<div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 md:pt-20 lg:px-8">
						<img className="mx-auto w-1/6" src={footerlogo} />

						<div className="mt-8 flex justify-center space-x-6">
							{navigation.social.map((item) => (
								<a
									key={item.name}
									href={item.href}
									className="text-white"
									target="_blank"
								>
									<span className="sr-only">{item.name}</span>
									<img
										src={item.icon}
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</a>
							))}
						</div>
						<nav
							className="hidden md:flex -mx-5 mt-8 flex-wrap justify-center"
							aria-label="Footer"
						>
							{navigation.main.map((item) => (
								<div key={item.name} className="px-5">
									<a
										href={item.href}
										className="text-base text-white"
									>
										{item.name}
									</a>
								</div>
							))}
						</nav>
						<p className="mt-8 text-center text-base text-white">
							&copy; 2022 PeaceMaker. All Rights Reserved -
							Powered By{" "}
							<a href="https://convrtx.com" target="_blank">
								Convrtx
							</a>
						</p>
					</div>
				</footer>
			</section>
		</div>
	);
}

export default App;
